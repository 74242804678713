var ts = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
};
function rs(e) {
  return e;
}
function Q(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return a(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return s(a(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(s(a(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(s(a(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return l(c(o(s(a(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function g(e, t, r, n, a, s, o, c, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return a(n(r(t(e))));
    case 6:
      return s(a(n(r(t(e)))));
    case 7:
      return o(s(a(n(r(t(e))))));
    case 8:
      return c(o(s(a(n(r(t(e)))))));
    case 9:
      return l(c(o(s(a(n(r(t(e))))))));
    default: {
      for (var u = arguments[0], h = 1; h < arguments.length; h++)
        u = arguments[h](u);
      return u;
    }
  }
}
var ns = function(e, t) {
  var r = typeof e == "number" ? function(n) {
    return n.length >= e;
  } : e;
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(a) {
      return t.apply(void 0, ts([a], n, !1));
    };
  };
}, as = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, ss = function(e) {
  return as(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
};
globalThis && globalThis.__spreadArray;
var $n = function(e) {
  return e._tag === "Some";
}, wn = { _tag: "None" }, An = function(e) {
  return { _tag: "Some", value: e };
}, is = function(e) {
  return e._tag === "Left";
}, os = function(e) {
  return e._tag === "Right";
}, cs = function(e) {
  return { _tag: "Left", left: e };
}, us = function(e) {
  return { _tag: "Right", right: e };
}, ls = function(e) {
  return [e];
}, ds = [], fs = Object.prototype.hasOwnProperty, ps = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, a) {
        return e.concat(n, a);
      }, t);
    };
  };
}, hs = function(e) {
  return {
    concat: function() {
      return e;
    }
  };
}, vs = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var a in e)
        fs.call(e, a) && (n[a] = e[a].concat(t[a], r[a]));
      return n;
    }
  };
}, Pt = function() {
  return { concat: rs };
}, ys = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, gs = ps, ms = hs(void 0), Or = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, _s = ds, bs = function(e, t) {
  return e < 0 || e >= t.length;
}, Ts = function(e) {
  return function(t) {
    return Or(Or([], t, !0), [e], !1);
  };
}, $s = Ts, ws = ls, K = {
  equals: function(e, t) {
    return e === t;
  }
}, As = {
  concat: function(e, t) {
    return e + t;
  }
}, Ss = "", er = {
  concat: As.concat,
  empty: Ss
};
K.equals;
var Sn = function(e) {
  return e.trim();
}, xn = function(e) {
  return e.length === 0;
}, Ye = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function En(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function xs() {
  this.__data__ = [], this.size = 0;
}
var Es = xs;
function Os(e, t) {
  return e === t || e !== e && t !== t;
}
var tr = Os, ks = tr;
function js(e, t) {
  for (var r = e.length; r--; )
    if (ks(e[r][0], t))
      return r;
  return -1;
}
var _t = js, Cs = _t, Is = Array.prototype, Rs = Is.splice;
function Ns(e) {
  var t = this.__data__, r = Cs(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : Rs.call(t, r, 1), --this.size, !0;
}
var Ps = Ns, Ls = _t;
function Ms(e) {
  var t = this.__data__, r = Ls(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var Vs = Ms, Zs = _t;
function Ds(e) {
  return Zs(this.__data__, e) > -1;
}
var Us = Ds, qs = _t;
function Bs(e, t) {
  var r = this.__data__, n = qs(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Hs = Bs, Gs = Es, zs = Ps, Fs = Vs, Ks = Us, Ws = Hs;
function be(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
be.prototype.clear = Gs;
be.prototype.delete = zs;
be.prototype.get = Fs;
be.prototype.has = Ks;
be.prototype.set = Ws;
var bt = be, Qs = bt;
function Js() {
  this.__data__ = new Qs(), this.size = 0;
}
var Ys = Js;
function Xs(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var ei = Xs;
function ti(e) {
  return this.__data__.get(e);
}
var ri = ti;
function ni(e) {
  return this.__data__.has(e);
}
var ai = ni, si = typeof Ye == "object" && Ye && Ye.Object === Object && Ye, On = si, ii = On, oi = typeof self == "object" && self && self.Object === Object && self, ci = ii || oi || Function("return this")(), G = ci, ui = G, li = ui.Symbol, Tt = li, kr = Tt, kn = Object.prototype, di = kn.hasOwnProperty, fi = kn.toString, xe = kr ? kr.toStringTag : void 0;
function pi(e) {
  var t = di.call(e, xe), r = e[xe];
  try {
    e[xe] = void 0;
    var n = !0;
  } catch {
  }
  var a = fi.call(e);
  return n && (t ? e[xe] = r : delete e[xe]), a;
}
var hi = pi, vi = Object.prototype, yi = vi.toString;
function gi(e) {
  return yi.call(e);
}
var mi = gi, jr = Tt, _i = hi, bi = mi, Ti = "[object Null]", $i = "[object Undefined]", Cr = jr ? jr.toStringTag : void 0;
function wi(e) {
  return e == null ? e === void 0 ? $i : Ti : Cr && Cr in Object(e) ? _i(e) : bi(e);
}
var $t = wi;
function Ai(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var Ue = Ai, Si = $t, xi = Ue, Ei = "[object AsyncFunction]", Oi = "[object Function]", ki = "[object GeneratorFunction]", ji = "[object Proxy]";
function Ci(e) {
  if (!xi(e))
    return !1;
  var t = Si(e);
  return t == Oi || t == ki || t == Ei || t == ji;
}
var jn = Ci, Ii = G, Ri = Ii["__core-js_shared__"], Ni = Ri, Lt = Ni, Ir = function() {
  var e = /[^.]+$/.exec(Lt && Lt.keys && Lt.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Pi(e) {
  return !!Ir && Ir in e;
}
var Li = Pi, Mi = Function.prototype, Vi = Mi.toString;
function Zi(e) {
  if (e != null) {
    try {
      return Vi.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var Cn = Zi, Di = jn, Ui = Li, qi = Ue, Bi = Cn, Hi = /[\\^$.*+?()[\]{}|]/g, Gi = /^\[object .+?Constructor\]$/, zi = Function.prototype, Fi = Object.prototype, Ki = zi.toString, Wi = Fi.hasOwnProperty, Qi = RegExp(
  "^" + Ki.call(Wi).replace(Hi, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Ji(e) {
  if (!qi(e) || Ui(e))
    return !1;
  var t = Di(e) ? Qi : Gi;
  return t.test(Bi(e));
}
var Yi = Ji;
function Xi(e, t) {
  return e == null ? void 0 : e[t];
}
var eo = Xi, to = Yi, ro = eo;
function no(e, t) {
  var r = ro(e, t);
  return to(r) ? r : void 0;
}
var ue = no, ao = ue, so = G, io = ao(so, "Map"), rr = io, oo = ue, co = oo(Object, "create"), wt = co, Rr = wt;
function uo() {
  this.__data__ = Rr ? Rr(null) : {}, this.size = 0;
}
var lo = uo;
function fo(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var po = fo, ho = wt, vo = "__lodash_hash_undefined__", yo = Object.prototype, go = yo.hasOwnProperty;
function mo(e) {
  var t = this.__data__;
  if (ho) {
    var r = t[e];
    return r === vo ? void 0 : r;
  }
  return go.call(t, e) ? t[e] : void 0;
}
var _o = mo, bo = wt, To = Object.prototype, $o = To.hasOwnProperty;
function wo(e) {
  var t = this.__data__;
  return bo ? t[e] !== void 0 : $o.call(t, e);
}
var Ao = wo, So = wt, xo = "__lodash_hash_undefined__";
function Eo(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = So && t === void 0 ? xo : t, this;
}
var Oo = Eo, ko = lo, jo = po, Co = _o, Io = Ao, Ro = Oo;
function Te(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Te.prototype.clear = ko;
Te.prototype.delete = jo;
Te.prototype.get = Co;
Te.prototype.has = Io;
Te.prototype.set = Ro;
var No = Te, Nr = No, Po = bt, Lo = rr;
function Mo() {
  this.size = 0, this.__data__ = {
    hash: new Nr(),
    map: new (Lo || Po)(),
    string: new Nr()
  };
}
var Vo = Mo;
function Zo(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Do = Zo, Uo = Do;
function qo(e, t) {
  var r = e.__data__;
  return Uo(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var At = qo, Bo = At;
function Ho(e) {
  var t = Bo(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Go = Ho, zo = At;
function Fo(e) {
  return zo(this, e).get(e);
}
var Ko = Fo, Wo = At;
function Qo(e) {
  return Wo(this, e).has(e);
}
var Jo = Qo, Yo = At;
function Xo(e, t) {
  var r = Yo(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var ec = Xo, tc = Vo, rc = Go, nc = Ko, ac = Jo, sc = ec;
function $e(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
$e.prototype.clear = tc;
$e.prototype.delete = rc;
$e.prototype.get = nc;
$e.prototype.has = ac;
$e.prototype.set = sc;
var In = $e, ic = bt, oc = rr, cc = In, uc = 200;
function lc(e, t) {
  var r = this.__data__;
  if (r instanceof ic) {
    var n = r.__data__;
    if (!oc || n.length < uc - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new cc(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var dc = lc, fc = bt, pc = Ys, hc = ei, vc = ri, yc = ai, gc = dc;
function we(e) {
  var t = this.__data__ = new fc(e);
  this.size = t.size;
}
we.prototype.clear = pc;
we.prototype.delete = hc;
we.prototype.get = vc;
we.prototype.has = yc;
we.prototype.set = gc;
var Rn = we;
function mc(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var _c = mc, bc = ue, Tc = function() {
  try {
    var e = bc(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), $c = Tc, Pr = $c;
function wc(e, t, r) {
  t == "__proto__" && Pr ? Pr(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var Nn = wc, Ac = Nn, Sc = tr, xc = Object.prototype, Ec = xc.hasOwnProperty;
function Oc(e, t, r) {
  var n = e[t];
  (!(Ec.call(e, t) && Sc(n, r)) || r === void 0 && !(t in e)) && Ac(e, t, r);
}
var Pn = Oc, kc = Pn, jc = Nn;
function Cc(e, t, r, n) {
  var a = !r;
  r || (r = {});
  for (var s = -1, o = t.length; ++s < o; ) {
    var c = t[s], l = n ? n(r[c], e[c], c, r, e) : void 0;
    l === void 0 && (l = e[c]), a ? jc(r, c, l) : kc(r, c, l);
  }
  return r;
}
var St = Cc;
function Ic(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var Rc = Ic;
function Nc(e) {
  return e != null && typeof e == "object";
}
var Ae = Nc, Pc = $t, Lc = Ae, Mc = "[object Arguments]";
function Vc(e) {
  return Lc(e) && Pc(e) == Mc;
}
var Zc = Vc, Lr = Zc, Dc = Ae, Ln = Object.prototype, Uc = Ln.hasOwnProperty, qc = Ln.propertyIsEnumerable, Bc = Lr(function() {
  return arguments;
}()) ? Lr : function(e) {
  return Dc(e) && Uc.call(e, "callee") && !qc.call(e, "callee");
}, Hc = Bc, Gc = Array.isArray, xt = Gc, rt = { exports: {} };
function zc() {
  return !1;
}
var Fc = zc;
rt.exports;
(function(e, t) {
  var r = G, n = Fc, a = t && !t.nodeType && t, s = a && !0 && e && !e.nodeType && e, o = s && s.exports === a, c = o ? r.Buffer : void 0, l = c ? c.isBuffer : void 0, u = l || n;
  e.exports = u;
})(rt, rt.exports);
var nr = rt.exports, Kc = 9007199254740991, Wc = /^(?:0|[1-9]\d*)$/;
function Qc(e, t) {
  var r = typeof e;
  return t = t ?? Kc, !!t && (r == "number" || r != "symbol" && Wc.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Jc = Qc, Yc = 9007199254740991;
function Xc(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Yc;
}
var Mn = Xc, eu = $t, tu = Mn, ru = Ae, nu = "[object Arguments]", au = "[object Array]", su = "[object Boolean]", iu = "[object Date]", ou = "[object Error]", cu = "[object Function]", uu = "[object Map]", lu = "[object Number]", du = "[object Object]", fu = "[object RegExp]", pu = "[object Set]", hu = "[object String]", vu = "[object WeakMap]", yu = "[object ArrayBuffer]", gu = "[object DataView]", mu = "[object Float32Array]", _u = "[object Float64Array]", bu = "[object Int8Array]", Tu = "[object Int16Array]", $u = "[object Int32Array]", wu = "[object Uint8Array]", Au = "[object Uint8ClampedArray]", Su = "[object Uint16Array]", xu = "[object Uint32Array]", S = {};
S[mu] = S[_u] = S[bu] = S[Tu] = S[$u] = S[wu] = S[Au] = S[Su] = S[xu] = !0;
S[nu] = S[au] = S[yu] = S[su] = S[gu] = S[iu] = S[ou] = S[cu] = S[uu] = S[lu] = S[du] = S[fu] = S[pu] = S[hu] = S[vu] = !1;
function Eu(e) {
  return ru(e) && tu(e.length) && !!S[eu(e)];
}
var Ou = Eu;
function ku(e) {
  return function(t) {
    return e(t);
  };
}
var ar = ku, nt = { exports: {} };
nt.exports;
(function(e, t) {
  var r = On, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s && r.process, c = function() {
    try {
      var l = a && a.require && a.require("util").types;
      return l || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(nt, nt.exports);
var sr = nt.exports, ju = Ou, Cu = ar, Mr = sr, Vr = Mr && Mr.isTypedArray, Iu = Vr ? Cu(Vr) : ju, Vn = Iu, Ru = Rc, Nu = Hc, Pu = xt, Lu = nr, Mu = Jc, Vu = Vn, Zu = Object.prototype, Du = Zu.hasOwnProperty;
function Uu(e, t) {
  var r = Pu(e), n = !r && Nu(e), a = !r && !n && Lu(e), s = !r && !n && !a && Vu(e), o = r || n || a || s, c = o ? Ru(e.length, String) : [], l = c.length;
  for (var u in e)
    (t || Du.call(e, u)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (u == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (u == "offset" || u == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (u == "buffer" || u == "byteLength" || u == "byteOffset") || // Skip index properties.
    Mu(u, l))) && c.push(u);
  return c;
}
var Zn = Uu, qu = Object.prototype;
function Bu(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || qu;
  return e === r;
}
var ir = Bu;
function Hu(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var Dn = Hu, Gu = Dn, zu = Gu(Object.keys, Object), Fu = zu, Ku = ir, Wu = Fu, Qu = Object.prototype, Ju = Qu.hasOwnProperty;
function Yu(e) {
  if (!Ku(e))
    return Wu(e);
  var t = [];
  for (var r in Object(e))
    Ju.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var Xu = Yu, el = jn, tl = Mn;
function rl(e) {
  return e != null && tl(e.length) && !el(e);
}
var Un = rl, nl = Zn, al = Xu, sl = Un;
function il(e) {
  return sl(e) ? nl(e) : al(e);
}
var or = il, ol = St, cl = or;
function ul(e, t) {
  return e && ol(t, cl(t), e);
}
var ll = ul;
function dl(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var fl = dl, pl = Ue, hl = ir, vl = fl, yl = Object.prototype, gl = yl.hasOwnProperty;
function ml(e) {
  if (!pl(e))
    return vl(e);
  var t = hl(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !gl.call(e, n)) || r.push(n);
  return r;
}
var _l = ml, bl = Zn, Tl = _l, $l = Un;
function wl(e) {
  return $l(e) ? bl(e, !0) : Tl(e);
}
var cr = wl, Al = St, Sl = cr;
function xl(e, t) {
  return e && Al(t, Sl(t), e);
}
var El = xl, at = { exports: {} };
at.exports;
(function(e, t) {
  var r = G, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, o = s ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function l(u, h) {
    if (h)
      return u.slice();
    var T = u.length, w = c ? c(T) : new u.constructor(T);
    return u.copy(w), w;
  }
  e.exports = l;
})(at, at.exports);
var Ol = at.exports;
function kl(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var jl = kl;
function Cl(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, s = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (s[a++] = o);
  }
  return s;
}
var Il = Cl;
function Rl() {
  return [];
}
var qn = Rl, Nl = Il, Pl = qn, Ll = Object.prototype, Ml = Ll.propertyIsEnumerable, Zr = Object.getOwnPropertySymbols, Vl = Zr ? function(e) {
  return e == null ? [] : (e = Object(e), Nl(Zr(e), function(t) {
    return Ml.call(e, t);
  }));
} : Pl, ur = Vl, Zl = St, Dl = ur;
function Ul(e, t) {
  return Zl(e, Dl(e), t);
}
var ql = Ul;
function Bl(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var Bn = Bl, Hl = Dn, Gl = Hl(Object.getPrototypeOf, Object), Hn = Gl, zl = Bn, Fl = Hn, Kl = ur, Wl = qn, Ql = Object.getOwnPropertySymbols, Jl = Ql ? function(e) {
  for (var t = []; e; )
    zl(t, Kl(e)), e = Fl(e);
  return t;
} : Wl, Gn = Jl, Yl = St, Xl = Gn;
function ed(e, t) {
  return Yl(e, Xl(e), t);
}
var td = ed, rd = Bn, nd = xt;
function ad(e, t, r) {
  var n = t(e);
  return nd(e) ? n : rd(n, r(e));
}
var zn = ad, sd = zn, id = ur, od = or;
function cd(e) {
  return sd(e, od, id);
}
var Fn = cd, ud = zn, ld = Gn, dd = cr;
function fd(e) {
  return ud(e, dd, ld);
}
var pd = fd, hd = ue, vd = G, yd = hd(vd, "DataView"), gd = yd, md = ue, _d = G, bd = md(_d, "Promise"), Td = bd, $d = ue, wd = G, Ad = $d(wd, "Set"), Sd = Ad, xd = ue, Ed = G, Od = xd(Ed, "WeakMap"), kd = Od, Ut = gd, qt = rr, Bt = Td, Ht = Sd, Gt = kd, Kn = $t, Se = Cn, Dr = "[object Map]", jd = "[object Object]", Ur = "[object Promise]", qr = "[object Set]", Br = "[object WeakMap]", Hr = "[object DataView]", Cd = Se(Ut), Id = Se(qt), Rd = Se(Bt), Nd = Se(Ht), Pd = Se(Gt), ae = Kn;
(Ut && ae(new Ut(new ArrayBuffer(1))) != Hr || qt && ae(new qt()) != Dr || Bt && ae(Bt.resolve()) != Ur || Ht && ae(new Ht()) != qr || Gt && ae(new Gt()) != Br) && (ae = function(e) {
  var t = Kn(e), r = t == jd ? e.constructor : void 0, n = r ? Se(r) : "";
  if (n)
    switch (n) {
      case Cd:
        return Hr;
      case Id:
        return Dr;
      case Rd:
        return Ur;
      case Nd:
        return qr;
      case Pd:
        return Br;
    }
  return t;
});
var Et = ae, Ld = Object.prototype, Md = Ld.hasOwnProperty;
function Vd(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && Md.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var Zd = Vd, Dd = G, Ud = Dd.Uint8Array, Wn = Ud, Gr = Wn;
function qd(e) {
  var t = new e.constructor(e.byteLength);
  return new Gr(t).set(new Gr(e)), t;
}
var lr = qd, Bd = lr;
function Hd(e, t) {
  var r = t ? Bd(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var Gd = Hd, zd = /\w*$/;
function Fd(e) {
  var t = new e.constructor(e.source, zd.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Kd = Fd, zr = Tt, Fr = zr ? zr.prototype : void 0, Kr = Fr ? Fr.valueOf : void 0;
function Wd(e) {
  return Kr ? Object(Kr.call(e)) : {};
}
var Qd = Wd, Jd = lr;
function Yd(e, t) {
  var r = t ? Jd(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var Xd = Yd, ef = lr, tf = Gd, rf = Kd, nf = Qd, af = Xd, sf = "[object Boolean]", of = "[object Date]", cf = "[object Map]", uf = "[object Number]", lf = "[object RegExp]", df = "[object Set]", ff = "[object String]", pf = "[object Symbol]", hf = "[object ArrayBuffer]", vf = "[object DataView]", yf = "[object Float32Array]", gf = "[object Float64Array]", mf = "[object Int8Array]", _f = "[object Int16Array]", bf = "[object Int32Array]", Tf = "[object Uint8Array]", $f = "[object Uint8ClampedArray]", wf = "[object Uint16Array]", Af = "[object Uint32Array]";
function Sf(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case hf:
      return ef(e);
    case sf:
    case of:
      return new n(+e);
    case vf:
      return tf(e, r);
    case yf:
    case gf:
    case mf:
    case _f:
    case bf:
    case Tf:
    case $f:
    case wf:
    case Af:
      return af(e, r);
    case cf:
      return new n();
    case uf:
    case ff:
      return new n(e);
    case lf:
      return rf(e);
    case df:
      return new n();
    case pf:
      return nf(e);
  }
}
var xf = Sf, Ef = Ue, Wr = Object.create, Of = function() {
  function e() {
  }
  return function(t) {
    if (!Ef(t))
      return {};
    if (Wr)
      return Wr(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), kf = Of, jf = kf, Cf = Hn, If = ir;
function Rf(e) {
  return typeof e.constructor == "function" && !If(e) ? jf(Cf(e)) : {};
}
var Nf = Rf, Pf = Et, Lf = Ae, Mf = "[object Map]";
function Vf(e) {
  return Lf(e) && Pf(e) == Mf;
}
var Zf = Vf, Df = Zf, Uf = ar, Qr = sr, Jr = Qr && Qr.isMap, qf = Jr ? Uf(Jr) : Df, Bf = qf, Hf = Et, Gf = Ae, zf = "[object Set]";
function Ff(e) {
  return Gf(e) && Hf(e) == zf;
}
var Kf = Ff, Wf = Kf, Qf = ar, Yr = sr, Xr = Yr && Yr.isSet, Jf = Xr ? Qf(Xr) : Wf, Yf = Jf, Xf = Rn, ep = _c, tp = Pn, rp = ll, np = El, ap = Ol, sp = jl, ip = ql, op = td, cp = Fn, up = pd, lp = Et, dp = Zd, fp = xf, pp = Nf, hp = xt, vp = nr, yp = Bf, gp = Ue, mp = Yf, _p = or, bp = cr, Tp = 1, $p = 2, wp = 4, Qn = "[object Arguments]", Ap = "[object Array]", Sp = "[object Boolean]", xp = "[object Date]", Ep = "[object Error]", Jn = "[object Function]", Op = "[object GeneratorFunction]", kp = "[object Map]", jp = "[object Number]", Yn = "[object Object]", Cp = "[object RegExp]", Ip = "[object Set]", Rp = "[object String]", Np = "[object Symbol]", Pp = "[object WeakMap]", Lp = "[object ArrayBuffer]", Mp = "[object DataView]", Vp = "[object Float32Array]", Zp = "[object Float64Array]", Dp = "[object Int8Array]", Up = "[object Int16Array]", qp = "[object Int32Array]", Bp = "[object Uint8Array]", Hp = "[object Uint8ClampedArray]", Gp = "[object Uint16Array]", zp = "[object Uint32Array]", A = {};
A[Qn] = A[Ap] = A[Lp] = A[Mp] = A[Sp] = A[xp] = A[Vp] = A[Zp] = A[Dp] = A[Up] = A[qp] = A[kp] = A[jp] = A[Yn] = A[Cp] = A[Ip] = A[Rp] = A[Np] = A[Bp] = A[Hp] = A[Gp] = A[zp] = !0;
A[Ep] = A[Jn] = A[Pp] = !1;
function et(e, t, r, n, a, s) {
  var o, c = t & Tp, l = t & $p, u = t & wp;
  if (r && (o = a ? r(e, n, a, s) : r(e)), o !== void 0)
    return o;
  if (!gp(e))
    return e;
  var h = hp(e);
  if (h) {
    if (o = dp(e), !c)
      return sp(e, o);
  } else {
    var T = lp(e), w = T == Jn || T == Op;
    if (vp(e))
      return ap(e, c);
    if (T == Yn || T == Qn || w && !a) {
      if (o = l || w ? {} : pp(e), !c)
        return l ? op(e, np(o, e)) : ip(e, rp(o, e));
    } else {
      if (!A[T])
        return a ? e : {};
      o = fp(e, T, c);
    }
  }
  s || (s = new Xf());
  var C = s.get(e);
  if (C)
    return C;
  s.set(e, o), mp(e) ? e.forEach(function(j) {
    o.add(et(j, t, r, j, e, s));
  }) : yp(e) && e.forEach(function(j, R) {
    o.set(R, et(j, t, r, R, e, s));
  });
  var P = u ? l ? up : cp : l ? bp : _p, L = h ? void 0 : P(e);
  return ep(L || e, function(j, R) {
    L && (R = j, j = e[R]), tp(o, R, et(j, t, r, R, e, s));
  }), o;
}
var Fp = et, Kp = Fp, Wp = 1, Qp = 4;
function Jp(e) {
  return Kp(e, Wp | Qp);
}
var Yp = Jp;
const Xp = /* @__PURE__ */ En(Yp);
var $;
(function(e) {
  e.assertEqual = (a) => a;
  function t(a) {
  }
  e.assertIs = t;
  function r(a) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (a) => {
    const s = {};
    for (const o of a)
      s[o] = o;
    return s;
  }, e.getValidEnumValues = (a) => {
    const s = e.objectKeys(a).filter((c) => typeof a[a[c]] != "number"), o = {};
    for (const c of s)
      o[c] = a[c];
    return e.objectValues(o);
  }, e.objectValues = (a) => e.objectKeys(a).map(function(s) {
    return a[s];
  }), e.objectKeys = typeof Object.keys == "function" ? (a) => Object.keys(a) : (a) => {
    const s = [];
    for (const o in a)
      Object.prototype.hasOwnProperty.call(a, o) && s.push(o);
    return s;
  }, e.find = (a, s) => {
    for (const o of a)
      if (s(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (a) => Number.isInteger(a) : (a) => typeof a == "number" && isFinite(a) && Math.floor(a) === a;
  function n(a, s = " | ") {
    return a.map((o) => typeof o == "string" ? `'${o}'` : o).join(s);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (a, s) => typeof s == "bigint" ? s.toString() : s;
})($ || ($ = {}));
var zt;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(zt || (zt = {}));
const f = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), W = (e) => {
  switch (typeof e) {
    case "undefined":
      return f.undefined;
    case "string":
      return f.string;
    case "number":
      return isNaN(e) ? f.nan : f.number;
    case "boolean":
      return f.boolean;
    case "function":
      return f.function;
    case "bigint":
      return f.bigint;
    case "symbol":
      return f.symbol;
    case "object":
      return Array.isArray(e) ? f.array : e === null ? f.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? f.promise : typeof Map < "u" && e instanceof Map ? f.map : typeof Set < "u" && e instanceof Set ? f.set : typeof Date < "u" && e instanceof Date ? f.date : f.object;
    default:
      return f.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), eh = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class V extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(s) {
      return s.message;
    }, n = { _errors: [] }, a = (s) => {
      for (const o of s.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(a);
        else if (o.code === "invalid_return_type")
          a(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          a(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, l = 0;
          for (; l < o.path.length; ) {
            const u = o.path[l];
            l === o.path.length - 1 ? (c[u] = c[u] || { _errors: [] }, c[u]._errors.push(r(o))) : c[u] = c[u] || { _errors: [] }, c = c[u], l++;
          }
        }
    };
    return a(this), n;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const a of this.issues)
      a.path.length > 0 ? (r[a.path[0]] = r[a.path[0]] || [], r[a.path[0]].push(t(a))) : n.push(t(a));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
V.create = (e) => new V(e);
const Oe = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === f.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let Xn = Oe;
function th(e) {
  Xn = e;
}
function st() {
  return Xn;
}
const it = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: a } = e, s = [...r, ...a.path || []], o = {
    ...a,
    path: s
  };
  let c = "";
  const l = n.filter((u) => !!u).slice().reverse();
  for (const u of l)
    c = u(o, { data: t, defaultError: c }).message;
  return {
    ...a,
    path: s,
    message: a.message || c
  };
}, rh = [];
function p(e, t) {
  const r = it({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      st(),
      Oe
      // then global default map
    ].filter((n) => !!n)
  });
  e.common.issues.push(r);
}
class I {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const a of r) {
      if (a.status === "aborted")
        return m;
      a.status === "dirty" && t.dirty(), n.push(a.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const a of r)
      n.push({
        key: await a.key,
        value: await a.value
      });
    return I.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const a of r) {
      const { key: s, value: o } = a;
      if (s.status === "aborted" || o.status === "aborted")
        return m;
      s.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), s.value !== "__proto__" && (typeof o.value < "u" || a.alwaysSet) && (n[s.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const m = Object.freeze({
  status: "aborted"
}), ea = (e) => ({ status: "dirty", value: e }), N = (e) => ({ status: "valid", value: e }), Ft = (e) => e.status === "aborted", Kt = (e) => e.status === "dirty", ke = (e) => e.status === "valid", ot = (e) => typeof Promise < "u" && e instanceof Promise;
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
class q {
  constructor(t, r, n, a) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = a;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const en = (e, t) => {
  if (ke(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new V(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function _(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: a } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: a } : { errorMap: (o, c) => o.code !== "invalid_type" ? { message: c.defaultError } : typeof c.data > "u" ? { message: n ?? c.defaultError } : { message: r ?? c.defaultError }, description: a };
}
class b {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return W(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: W(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new I(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: W(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (ot(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const a = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: W(t)
    }, s = this._parseSync({ data: t, path: a.path, parent: a });
    return en(a, s);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: W(t)
    }, a = this._parse({ data: t, path: n.path, parent: n }), s = await (ot(a) ? a : Promise.resolve(a));
    return en(n, s);
  }
  refine(t, r) {
    const n = (a) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(a) : r;
    return this._refinement((a, s) => {
      const o = t(a), c = () => s.addIssue({
        code: d.custom,
        ...n(a)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((l) => l ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, a) => t(n) ? !0 : (a.addIssue(typeof r == "function" ? r(n, a) : r), !1));
  }
  _refinement(t) {
    return new D({
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return z.create(this, this._def);
  }
  nullable() {
    return ce.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return Z.create(this, this._def);
  }
  promise() {
    return ve.create(this, this._def);
  }
  or(t) {
    return Re.create([this, t], this._def);
  }
  and(t) {
    return Ne.create(this, t, this._def);
  }
  transform(t) {
    return new D({
      ..._(this._def),
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Ze({
      ..._(this._def),
      innerType: this,
      defaultValue: r,
      typeName: y.ZodDefault
    });
  }
  brand() {
    return new ra({
      typeName: y.ZodBranded,
      type: this,
      ..._(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new dt({
      ..._(this._def),
      innerType: this,
      catchValue: r,
      typeName: y.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return qe.create(this, t);
  }
  readonly() {
    return pt.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const nh = /^c[^\s-]{8,}$/i, ah = /^[a-z][a-z0-9]*$/, sh = /^[0-9A-HJKMNP-TV-Z]{26}$/, ih = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, oh = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, ch = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let Mt;
const uh = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/, lh = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, dh = (e) => e.precision ? e.offset ? new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`) : new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}Z$`) : e.precision === 0 ? e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$") : e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$");
function fh(e, t) {
  return !!((t === "v4" || !t) && uh.test(e) || (t === "v6" || !t) && lh.test(e));
}
class M extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== f.string) {
      const s = this._getOrReturnCtx(t);
      return p(
        s,
        {
          code: d.invalid_type,
          expected: f.string,
          received: s.parsedType
        }
        //
      ), m;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      if (s.kind === "min")
        t.data.length < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "max")
        t.data.length > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "length") {
        const o = t.data.length > s.value, c = t.data.length < s.value;
        (o || c) && (a = this._getOrReturnCtx(t, a), o ? p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }) : c && p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }), n.dirty());
      } else if (s.kind === "email")
        oh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "email",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "emoji")
        Mt || (Mt = new RegExp(ch, "u")), Mt.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "emoji",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "uuid")
        ih.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "uuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid")
        nh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid2")
        ah.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid2",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "ulid")
        sh.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ulid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "url")
        try {
          new URL(t.data);
        } catch {
          a = this._getOrReturnCtx(t, a), p(a, {
            validation: "url",
            code: d.invalid_string,
            message: s.message
          }), n.dirty();
        }
      else
        s.kind === "regex" ? (s.regex.lastIndex = 0, s.regex.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "regex",
          code: d.invalid_string,
          message: s.message
        }), n.dirty())) : s.kind === "trim" ? t.data = t.data.trim() : s.kind === "includes" ? t.data.includes(s.value, s.position) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { includes: s.value, position: s.position },
          message: s.message
        }), n.dirty()) : s.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : s.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : s.kind === "startsWith" ? t.data.startsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { startsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "endsWith" ? t.data.endsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { endsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "datetime" ? dh(s).test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: "datetime",
          message: s.message
        }), n.dirty()) : s.kind === "ip" ? fh(t.data, s.version) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ip",
          code: d.invalid_string,
          message: s.message
        }), n.dirty()) : $.assertNever(s);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((a) => t.test(a), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new M({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new M({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new M({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new M({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
M.create = (e) => {
  var t;
  return new M({
    checks: [],
    typeName: y.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
function ph(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, a = r > n ? r : n, s = parseInt(e.toFixed(a).replace(".", "")), o = parseInt(t.toFixed(a).replace(".", ""));
  return s % o / Math.pow(10, a);
}
class J extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== f.number) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.number,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: s.message
      }), a.dirty()) : s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? ph(t.data, s.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : s.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new J({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new J({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
J.create = (e) => new J({
  checks: [],
  typeName: y.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class Y extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== f.bigint) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.bigint,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? t.data % s.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new Y({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new Y({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
Y.create = (e) => {
  var t;
  return new Y({
    checks: [],
    typeName: y.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
class je extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== f.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.boolean,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
je.create = (e) => new je({
  typeName: y.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class ie extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== f.date) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.date,
        received: s.parsedType
      }), m;
    }
    if (isNaN(t.data.getTime())) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_date
      }), m;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      s.kind === "min" ? t.data.getTime() < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_small,
        message: s.message,
        inclusive: !0,
        exact: !1,
        minimum: s.value,
        type: "date"
      }), n.dirty()) : s.kind === "max" ? t.data.getTime() > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_big,
        message: s.message,
        inclusive: !0,
        exact: !1,
        maximum: s.value,
        type: "date"
      }), n.dirty()) : $.assertNever(s);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ie({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ie.create = (e) => new ie({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: y.ZodDate,
  ..._(e)
});
class ct extends b {
  _parse(t) {
    if (this._getType(t) !== f.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.symbol,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ct.create = (e) => new ct({
  typeName: y.ZodSymbol,
  ..._(e)
});
class Ce extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.undefined,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
Ce.create = (e) => new Ce({
  typeName: y.ZodUndefined,
  ..._(e)
});
class Ie extends b {
  _parse(t) {
    if (this._getType(t) !== f.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.null,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
Ie.create = (e) => new Ie({
  typeName: y.ZodNull,
  ..._(e)
});
class he extends b {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
he.create = (e) => new he({
  typeName: y.ZodAny,
  ..._(e)
});
class se extends b {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
se.create = (e) => new se({
  typeName: y.ZodUnknown,
  ..._(e)
});
class F extends b {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: f.never,
      received: r.parsedType
    }), m;
  }
}
F.create = (e) => new F({
  typeName: y.ZodNever,
  ..._(e)
});
class ut extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.void,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ut.create = (e) => new ut({
  typeName: y.ZodVoid,
  ..._(e)
});
class Z extends b {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), a = this._def;
    if (r.parsedType !== f.array)
      return p(r, {
        code: d.invalid_type,
        expected: f.array,
        received: r.parsedType
      }), m;
    if (a.exactLength !== null) {
      const o = r.data.length > a.exactLength.value, c = r.data.length < a.exactLength.value;
      (o || c) && (p(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? a.exactLength.value : void 0,
        maximum: o ? a.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: a.exactLength.message
      }), n.dirty());
    }
    if (a.minLength !== null && r.data.length < a.minLength.value && (p(r, {
      code: d.too_small,
      minimum: a.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.minLength.message
    }), n.dirty()), a.maxLength !== null && r.data.length > a.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: a.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => a.type._parseAsync(new q(r, o, r.path, c)))).then((o) => I.mergeArray(n, o));
    const s = [...r.data].map((o, c) => a.type._parseSync(new q(r, o, r.path, c)));
    return I.mergeArray(n, s);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new Z({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new Z({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new Z({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
Z.create = (e, t) => new Z({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: y.ZodArray,
  ..._(t)
});
function fe(e) {
  if (e instanceof x) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = z.create(fe(n));
    }
    return new x({
      ...e._def,
      shape: () => t
    });
  } else
    return e instanceof Z ? new Z({
      ...e._def,
      type: fe(e.element)
    }) : e instanceof z ? z.create(fe(e.unwrap())) : e instanceof ce ? ce.create(fe(e.unwrap())) : e instanceof B ? B.create(e.items.map((t) => fe(t))) : e;
}
class x extends b {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== f.object) {
      const u = this._getOrReturnCtx(t);
      return p(u, {
        code: d.invalid_type,
        expected: f.object,
        received: u.parsedType
      }), m;
    }
    const { status: n, ctx: a } = this._processInputParams(t), { shape: s, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof F && this._def.unknownKeys === "strip"))
      for (const u in a.data)
        o.includes(u) || c.push(u);
    const l = [];
    for (const u of o) {
      const h = s[u], T = a.data[u];
      l.push({
        key: { status: "valid", value: u },
        value: h._parse(new q(a, T, a.path, u)),
        alwaysSet: u in a.data
      });
    }
    if (this._def.catchall instanceof F) {
      const u = this._def.unknownKeys;
      if (u === "passthrough")
        for (const h of c)
          l.push({
            key: { status: "valid", value: h },
            value: { status: "valid", value: a.data[h] }
          });
      else if (u === "strict")
        c.length > 0 && (p(a, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (u !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const u = this._def.catchall;
      for (const h of c) {
        const T = a.data[h];
        l.push({
          key: { status: "valid", value: h },
          value: u._parse(
            new q(a, T, a.path, h)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: h in a.data
        });
      }
    }
    return a.common.async ? Promise.resolve().then(async () => {
      const u = [];
      for (const h of l) {
        const T = await h.key;
        u.push({
          key: T,
          value: await h.value,
          alwaysSet: h.alwaysSet
        });
      }
      return u;
    }).then((u) => I.mergeObjectSync(n, u)) : I.mergeObjectSync(n, l);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new x({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var a, s, o, c;
          const l = (o = (s = (a = this._def).errorMap) === null || s === void 0 ? void 0 : s.call(a, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : l
          } : {
            message: l
          };
        }
      } : {}
    });
  }
  strip() {
    return new x({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new x({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new x({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new x({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: y.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new x({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return fe(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const a = this.shape[n];
      t && !t[n] ? r[n] = a : r[n] = a.optional();
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let s = this.shape[n];
        for (; s instanceof z; )
          s = s._def.innerType;
        r[n] = s;
      }
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return ta($.objectKeys(this.shape));
  }
}
x.create = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strip",
  catchall: F.create(),
  typeName: y.ZodObject,
  ..._(t)
});
x.strictCreate = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strict",
  catchall: F.create(),
  typeName: y.ZodObject,
  ..._(t)
});
x.lazycreate = (e, t) => new x({
  shape: e,
  unknownKeys: "strip",
  catchall: F.create(),
  typeName: y.ZodObject,
  ..._(t)
});
class Re extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function a(s) {
      for (const c of s)
        if (c.result.status === "valid")
          return c.result;
      for (const c of s)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = s.map((c) => new V(c.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), m;
    }
    if (r.common.async)
      return Promise.all(n.map(async (s) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await s._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(a);
    {
      let s;
      const o = [];
      for (const l of n) {
        const u = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, h = l._parseSync({
          data: r.data,
          path: r.path,
          parent: u
        });
        if (h.status === "valid")
          return h;
        h.status === "dirty" && !s && (s = { result: h, ctx: u }), u.common.issues.length && o.push(u.common.issues);
      }
      if (s)
        return r.common.issues.push(...s.ctx.common.issues), s.result;
      const c = o.map((l) => new V(l));
      return p(r, {
        code: d.invalid_union,
        unionErrors: c
      }), m;
    }
  }
  get options() {
    return this._def.options;
  }
}
Re.create = (e, t) => new Re({
  options: e,
  typeName: y.ZodUnion,
  ..._(t)
});
const tt = (e) => e instanceof Le ? tt(e.schema) : e instanceof D ? tt(e.innerType()) : e instanceof Me ? [e.value] : e instanceof X ? e.options : e instanceof Ve ? Object.keys(e.enum) : e instanceof Ze ? tt(e._def.innerType) : e instanceof Ce ? [void 0] : e instanceof Ie ? [null] : null;
class Ot extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.object)
      return p(r, {
        code: d.invalid_type,
        expected: f.object,
        received: r.parsedType
      }), m;
    const n = this.discriminator, a = r.data[n], s = this.optionsMap.get(a);
    return s ? r.common.async ? s._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : s._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), m);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const a = /* @__PURE__ */ new Map();
    for (const s of r) {
      const o = tt(s.shape[t]);
      if (!o)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (a.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        a.set(c, s);
      }
    }
    return new Ot({
      typeName: y.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: a,
      ..._(n)
    });
  }
}
function Wt(e, t) {
  const r = W(e), n = W(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === f.object && n === f.object) {
    const a = $.objectKeys(t), s = $.objectKeys(e).filter((c) => a.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of s) {
      const l = Wt(e[c], t[c]);
      if (!l.valid)
        return { valid: !1 };
      o[c] = l.data;
    }
    return { valid: !0, data: o };
  } else if (r === f.array && n === f.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const a = [];
    for (let s = 0; s < e.length; s++) {
      const o = e[s], c = t[s], l = Wt(o, c);
      if (!l.valid)
        return { valid: !1 };
      a.push(l.data);
    }
    return { valid: !0, data: a };
  } else
    return r === f.date && n === f.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Ne extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = (s, o) => {
      if (Ft(s) || Ft(o))
        return m;
      const c = Wt(s.value, o.value);
      return c.valid ? ((Kt(s) || Kt(o)) && r.dirty(), { status: r.value, value: c.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), m);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([s, o]) => a(s, o)) : a(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Ne.create = (e, t, r) => new Ne({
  left: e,
  right: t,
  typeName: y.ZodIntersection,
  ..._(r)
});
class B extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.array)
      return p(n, {
        code: d.invalid_type,
        expected: f.array,
        received: n.parsedType
      }), m;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), m;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const s = [...n.data].map((o, c) => {
      const l = this._def.items[c] || this._def.rest;
      return l ? l._parse(new q(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(s).then((o) => I.mergeArray(r, o)) : I.mergeArray(r, s);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new B({
      ...this._def,
      rest: t
    });
  }
}
B.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new B({
    items: e,
    typeName: y.ZodTuple,
    rest: null,
    ..._(t)
  });
};
class Pe extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.object)
      return p(n, {
        code: d.invalid_type,
        expected: f.object,
        received: n.parsedType
      }), m;
    const a = [], s = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      a.push({
        key: s._parse(new q(n, c, n.path, c)),
        value: o._parse(new q(n, n.data[c], n.path, c))
      });
    return n.common.async ? I.mergeObjectAsync(r, a) : I.mergeObjectSync(r, a);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof b ? new Pe({
      keyType: t,
      valueType: r,
      typeName: y.ZodRecord,
      ..._(n)
    }) : new Pe({
      keyType: M.create(),
      valueType: t,
      typeName: y.ZodRecord,
      ..._(r)
    });
  }
}
class lt extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.map)
      return p(n, {
        code: d.invalid_type,
        expected: f.map,
        received: n.parsedType
      }), m;
    const a = this._def.keyType, s = this._def.valueType, o = [...n.data.entries()].map(([c, l], u) => ({
      key: a._parse(new q(n, c, n.path, [u, "key"])),
      value: s._parse(new q(n, l, n.path, [u, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const l of o) {
          const u = await l.key, h = await l.value;
          if (u.status === "aborted" || h.status === "aborted")
            return m;
          (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const l of o) {
        const u = l.key, h = l.value;
        if (u.status === "aborted" || h.status === "aborted")
          return m;
        (u.status === "dirty" || h.status === "dirty") && r.dirty(), c.set(u.value, h.value);
      }
      return { status: r.value, value: c };
    }
  }
}
lt.create = (e, t, r) => new lt({
  valueType: t,
  keyType: e,
  typeName: y.ZodMap,
  ..._(r)
});
class oe extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.set)
      return p(n, {
        code: d.invalid_type,
        expected: f.set,
        received: n.parsedType
      }), m;
    const a = this._def;
    a.minSize !== null && n.data.size < a.minSize.value && (p(n, {
      code: d.too_small,
      minimum: a.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.minSize.message
    }), r.dirty()), a.maxSize !== null && n.data.size > a.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: a.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.maxSize.message
    }), r.dirty());
    const s = this._def.valueType;
    function o(l) {
      const u = /* @__PURE__ */ new Set();
      for (const h of l) {
        if (h.status === "aborted")
          return m;
        h.status === "dirty" && r.dirty(), u.add(h.value);
      }
      return { status: r.value, value: u };
    }
    const c = [...n.data.values()].map((l, u) => s._parse(new q(n, l, n.path, u)));
    return n.common.async ? Promise.all(c).then((l) => o(l)) : o(c);
  }
  min(t, r) {
    return new oe({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new oe({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
oe.create = (e, t) => new oe({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: y.ZodSet,
  ..._(t)
});
class pe extends b {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.function)
      return p(r, {
        code: d.invalid_type,
        expected: f.function,
        received: r.parsedType
      }), m;
    function n(c, l) {
      return it({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Oe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: l
        }
      });
    }
    function a(c, l) {
      return it({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Oe
        ].filter((u) => !!u),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: l
        }
      });
    }
    const s = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof ve) {
      const c = this;
      return N(async function(...l) {
        const u = new V([]), h = await c._def.args.parseAsync(l, s).catch((C) => {
          throw u.addIssue(n(l, C)), u;
        }), T = await Reflect.apply(o, this, h);
        return await c._def.returns._def.type.parseAsync(T, s).catch((C) => {
          throw u.addIssue(a(T, C)), u;
        });
      });
    } else {
      const c = this;
      return N(function(...l) {
        const u = c._def.args.safeParse(l, s);
        if (!u.success)
          throw new V([n(l, u.error)]);
        const h = Reflect.apply(o, this, u.data), T = c._def.returns.safeParse(h, s);
        if (!T.success)
          throw new V([a(h, T.error)]);
        return T.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new pe({
      ...this._def,
      args: B.create(t).rest(se.create())
    });
  }
  returns(t) {
    return new pe({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new pe({
      args: t || B.create([]).rest(se.create()),
      returns: r || se.create(),
      typeName: y.ZodFunction,
      ..._(n)
    });
  }
}
class Le extends b {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Le.create = (e, t) => new Le({
  getter: e,
  typeName: y.ZodLazy,
  ..._(t)
});
class Me extends b {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), m;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Me.create = (e, t) => new Me({
  value: e,
  typeName: y.ZodLiteral,
  ..._(t)
});
function ta(e, t) {
  return new X({
    values: e,
    typeName: y.ZodEnum,
    ..._(t)
  });
}
class X extends b {
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (this._def.values.indexOf(t.data) === -1) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), m;
    }
    return N(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t) {
    return X.create(t);
  }
  exclude(t) {
    return X.create(this.options.filter((r) => !t.includes(r)));
  }
}
X.create = ta;
class Ve extends b {
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== f.string && n.parsedType !== f.number) {
      const a = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(a),
        received: n.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (r.indexOf(t.data) === -1) {
      const a = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: a
      }), m;
    }
    return N(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
Ve.create = (e, t) => new Ve({
  values: e,
  typeName: y.ZodNativeEnum,
  ..._(t)
});
class ve extends b {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: f.promise,
        received: r.parsedType
      }), m;
    const n = r.parsedType === f.promise ? r.data : Promise.resolve(r.data);
    return N(n.then((a) => this._def.type.parseAsync(a, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
ve.create = (e, t) => new ve({
  type: e,
  typeName: y.ZodPromise,
  ..._(t)
});
class D extends b {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === y.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = this._def.effect || null, s = {
      addIssue: (o) => {
        p(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (s.addIssue = s.addIssue.bind(s), a.type === "preprocess") {
      const o = a.transform(n.data, s);
      return n.common.issues.length ? {
        status: "dirty",
        value: n.data
      } : n.common.async ? Promise.resolve(o).then((c) => this._def.schema._parseAsync({
        data: c,
        path: n.path,
        parent: n
      })) : this._def.schema._parseSync({
        data: o,
        path: n.path,
        parent: n
      });
    }
    if (a.type === "refinement") {
      const o = (c) => {
        const l = a.refinement(c, s);
        if (n.common.async)
          return Promise.resolve(l);
        if (l instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? m : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? m : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (a.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!ke(o))
          return o;
        const c = a.transform(o.value, s);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => ke(o) ? Promise.resolve(a.transform(o.value, s)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(a);
  }
}
D.create = (e, t, r) => new D({
  schema: e,
  typeName: y.ZodEffects,
  effect: t,
  ..._(r)
});
D.createWithPreprocess = (e, t, r) => new D({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: y.ZodEffects,
  ..._(r)
});
class z extends b {
  _parse(t) {
    return this._getType(t) === f.undefined ? N(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
z.create = (e, t) => new z({
  innerType: e,
  typeName: y.ZodOptional,
  ..._(t)
});
class ce extends b {
  _parse(t) {
    return this._getType(t) === f.null ? N(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
ce.create = (e, t) => new ce({
  innerType: e,
  typeName: y.ZodNullable,
  ..._(t)
});
class Ze extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === f.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Ze.create = (e, t) => new Ze({
  innerType: e,
  typeName: y.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ..._(t)
});
class dt extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, a = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return ot(a) ? a.then((s) => ({
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new V(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: a.status === "valid" ? a.value : this._def.catchValue({
        get error() {
          return new V(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
dt.create = (e, t) => new dt({
  innerType: e,
  typeName: y.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ..._(t)
});
class ft extends b {
  _parse(t) {
    if (this._getType(t) !== f.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.nan,
        received: n.parsedType
      }), m;
    }
    return { status: "valid", value: t.data };
  }
}
ft.create = (e) => new ft({
  typeName: y.ZodNaN,
  ..._(e)
});
const hh = Symbol("zod_brand");
class ra extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class qe extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const s = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return s.status === "aborted" ? m : s.status === "dirty" ? (r.dirty(), ea(s.value)) : this._def.out._parseAsync({
          data: s.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const a = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return a.status === "aborted" ? m : a.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: a.value
      }) : this._def.out._parseSync({
        data: a.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new qe({
      in: t,
      out: r,
      typeName: y.ZodPipeline
    });
  }
}
class pt extends b {
  _parse(t) {
    const r = this._def.innerType._parse(t);
    return ke(r) && (r.value = Object.freeze(r.value)), r;
  }
}
pt.create = (e, t) => new pt({
  innerType: e,
  typeName: y.ZodReadonly,
  ..._(t)
});
const na = (e, t = {}, r) => e ? he.create().superRefine((n, a) => {
  var s, o;
  if (!e(n)) {
    const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, l = (o = (s = c.fatal) !== null && s !== void 0 ? s : r) !== null && o !== void 0 ? o : !0, u = typeof c == "string" ? { message: c } : c;
    a.addIssue({ code: "custom", ...u, fatal: l });
  }
}) : he.create(), vh = {
  object: x.lazycreate
};
var y;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(y || (y = {}));
const yh = (e, t = {
  message: `Input not instance of ${e.name}`
}) => na((r) => r instanceof e, t), aa = M.create, sa = J.create, gh = ft.create, mh = Y.create, ia = je.create, _h = ie.create, bh = ct.create, Th = Ce.create, $h = Ie.create, wh = he.create, Ah = se.create, Sh = F.create, xh = ut.create, Eh = Z.create, Oh = x.create, kh = x.strictCreate, jh = Re.create, Ch = Ot.create, Ih = Ne.create, Rh = B.create, Nh = Pe.create, Ph = lt.create, Lh = oe.create, Mh = pe.create, Vh = Le.create, Zh = Me.create, Dh = X.create, Uh = Ve.create, qh = ve.create, tn = D.create, Bh = z.create, Hh = ce.create, Gh = D.createWithPreprocess, zh = qe.create, Fh = () => aa().optional(), Kh = () => sa().optional(), Wh = () => ia().optional(), Qh = {
  string: (e) => M.create({ ...e, coerce: !0 }),
  number: (e) => J.create({ ...e, coerce: !0 }),
  boolean: (e) => je.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => Y.create({ ...e, coerce: !0 }),
  date: (e) => ie.create({ ...e, coerce: !0 })
}, Jh = m;
var i = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: Oe,
  setErrorMap: th,
  getErrorMap: st,
  makeIssue: it,
  EMPTY_PATH: rh,
  addIssueToContext: p,
  ParseStatus: I,
  INVALID: m,
  DIRTY: ea,
  OK: N,
  isAborted: Ft,
  isDirty: Kt,
  isValid: ke,
  isAsync: ot,
  get util() {
    return $;
  },
  get objectUtil() {
    return zt;
  },
  ZodParsedType: f,
  getParsedType: W,
  ZodType: b,
  ZodString: M,
  ZodNumber: J,
  ZodBigInt: Y,
  ZodBoolean: je,
  ZodDate: ie,
  ZodSymbol: ct,
  ZodUndefined: Ce,
  ZodNull: Ie,
  ZodAny: he,
  ZodUnknown: se,
  ZodNever: F,
  ZodVoid: ut,
  ZodArray: Z,
  ZodObject: x,
  ZodUnion: Re,
  ZodDiscriminatedUnion: Ot,
  ZodIntersection: Ne,
  ZodTuple: B,
  ZodRecord: Pe,
  ZodMap: lt,
  ZodSet: oe,
  ZodFunction: pe,
  ZodLazy: Le,
  ZodLiteral: Me,
  ZodEnum: X,
  ZodNativeEnum: Ve,
  ZodPromise: ve,
  ZodEffects: D,
  ZodTransformer: D,
  ZodOptional: z,
  ZodNullable: ce,
  ZodDefault: Ze,
  ZodCatch: dt,
  ZodNaN: ft,
  BRAND: hh,
  ZodBranded: ra,
  ZodPipeline: qe,
  ZodReadonly: pt,
  custom: na,
  Schema: b,
  ZodSchema: b,
  late: vh,
  get ZodFirstPartyTypeKind() {
    return y;
  },
  coerce: Qh,
  any: wh,
  array: Eh,
  bigint: mh,
  boolean: ia,
  date: _h,
  discriminatedUnion: Ch,
  effect: tn,
  enum: Dh,
  function: Mh,
  instanceof: yh,
  intersection: Ih,
  lazy: Vh,
  literal: Zh,
  map: Ph,
  nan: gh,
  nativeEnum: Uh,
  never: Sh,
  null: $h,
  nullable: Hh,
  number: sa,
  object: Oh,
  oboolean: Wh,
  onumber: Kh,
  optional: Bh,
  ostring: Fh,
  pipeline: zh,
  preprocess: Gh,
  promise: qh,
  record: Nh,
  set: Lh,
  strictObject: kh,
  string: aa,
  symbol: bh,
  transformer: tn,
  tuple: Rh,
  undefined: Th,
  union: jh,
  unknown: Ah,
  void: xh,
  NEVER: Jh,
  ZodIssueCode: d,
  quotelessJson: eh,
  ZodError: V
}), U = (e) => e, Yh = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let a = e;
    for (let s = t + 1; s <= this.latestVersion; s++) {
      const o = this.versionMap[s];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: s }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: s }
        };
      a = o.up(a);
    }
    return { type: "ok", value: a };
  }
};
function kt(e) {
  return new Yh(e.versionMap, e.latestVersion, e.getVersion);
}
function ht(e) {
  return i.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
const Xh = i.object({
  id: i.optional(i.string()),
  // Firebase Firestore ID
  url: i.string(),
  path: i.string(),
  headers: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  params: i.array(
    i.object({
      key: i.string(),
      value: i.string(),
      active: i.boolean()
    })
  ),
  name: i.string(),
  method: i.string(),
  preRequestScript: i.string(),
  testScript: i.string(),
  contentType: i.string(),
  body: i.string(),
  rawParams: i.optional(i.string()),
  auth: i.optional(i.string()),
  httpUser: i.optional(i.string()),
  httpPassword: i.optional(i.string()),
  bearerToken: i.optional(i.string())
}), rn = U({
  initial: !0,
  schema: Xh
}), oa = i.object({
  key: i.string(),
  active: i.boolean()
}).and(
  i.union([
    i.object({
      isFile: i.literal(!0),
      value: i.array(i.instanceof(Blob).nullable())
    }),
    i.object({
      isFile: i.literal(!1),
      value: i.string()
    })
  ])
), sm = i.object({
  contentType: i.literal("multipart/form-data"),
  body: i.array(oa)
}), ca = i.union([
  i.object({
    contentType: i.literal(null),
    body: i.literal(null).catch(null)
  }),
  i.object({
    contentType: i.literal("multipart/form-data"),
    body: i.array(oa).catch([])
  }),
  i.object({
    contentType: i.union([
      i.literal("application/json"),
      i.literal("application/ld+json"),
      i.literal("application/hal+json"),
      i.literal("application/vnd.api+json"),
      i.literal("application/xml"),
      i.literal("application/x-www-form-urlencoded"),
      i.literal("text/html"),
      i.literal("text/plain")
    ]),
    body: i.string().catch("")
  })
]), ua = i.object({
  authType: i.literal("none")
}), la = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), da = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), ev = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), fa = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), pa = i.object({
  authType: i.literal("inherit")
}), tv = i.discriminatedUnion("authType", [
  ua,
  pa,
  la,
  da,
  ev,
  fa
]).and(
  i.object({
    authActive: i.boolean()
  })
), ha = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), dr = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), va = i.object({
  v: i.literal("1"),
  id: i.optional(i.string()),
  // Firebase Firestore ID
  name: i.string(),
  method: i.string(),
  endpoint: i.string(),
  params: ha,
  headers: dr,
  preRequestScript: i.string().catch(""),
  testScript: i.string().catch(""),
  auth: tv,
  body: ca
});
function rv(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function nv(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const av = U({
  initial: !1,
  schema: va,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l
    } = e, u = `${t}${r}`, h = rv(e), T = nv(e), w = {
      v: "1",
      endpoint: u,
      headers: n,
      params: a,
      name: s,
      method: o,
      preRequestScript: c,
      testScript: l,
      body: h,
      auth: T
    };
    return e.id && (w.id = e.id), w;
  }
}), ya = i.array(
  i.object({
    key: i.string().catch(""),
    value: i.string().catch(""),
    active: i.boolean().catch(!0)
  })
), ga = va.extend({
  v: i.literal("2"),
  requestVariables: ya
}), sv = U({
  initial: !1,
  schema: ga,
  up(e) {
    return {
      ...e,
      v: "2",
      requestVariables: []
    };
  }
}), iv = i.object({
  grantType: i.literal("AUTHORIZATION_CODE"),
  authEndpoint: i.string().trim(),
  tokenEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch(""),
  isPKCE: i.boolean(),
  codeVerifierMethod: i.union([i.literal("plain"), i.literal("S256")]).optional()
}), ov = i.object({
  grantType: i.literal("CLIENT_CREDENTIALS"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch("")
}), cv = i.object({
  grantType: i.literal("PASSWORD"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  clientSecret: i.string().trim(),
  scopes: i.string().trim().optional(),
  username: i.string().trim(),
  password: i.string().trim(),
  token: i.string().catch("")
}), uv = i.object({
  grantType: i.literal("IMPLICIT"),
  authEndpoint: i.string().trim(),
  clientID: i.string().trim(),
  scopes: i.string().trim().optional(),
  token: i.string().catch("")
}), ma = i.object({
  authType: i.literal("oauth-2"),
  grantTypeInfo: i.discriminatedUnion("grantType", [
    iv,
    ov,
    cv,
    uv
  ]),
  addTo: i.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), fr = i.discriminatedUnion("authType", [
  ua,
  pa,
  la,
  da,
  ma,
  fa
]).and(
  i.object({
    authActive: i.boolean()
  })
), lv = ga.extend({
  v: i.literal("3"),
  auth: fr
}), dv = U({
  initial: !1,
  schema: lv,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: a, authURL: s } = e.auth;
      return {
        ...e,
        v: "3",
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: s,
            tokenEndpoint: r,
            clientID: a,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: "3",
      auth: {
        ...e.auth
      }
    };
  }
});
var fv = "__lodash_hash_undefined__";
function pv(e) {
  return this.__data__.set(e, fv), this;
}
var hv = pv;
function vv(e) {
  return this.__data__.has(e);
}
var yv = vv, gv = In, mv = hv, _v = yv;
function vt(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new gv(); ++t < r; )
    this.add(e[t]);
}
vt.prototype.add = vt.prototype.push = mv;
vt.prototype.has = _v;
var bv = vt;
function Tv(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var $v = Tv;
function wv(e, t) {
  return e.has(t);
}
var Av = wv, Sv = bv, xv = $v, Ev = Av, Ov = 1, kv = 2;
function jv(e, t, r, n, a, s) {
  var o = r & Ov, c = e.length, l = t.length;
  if (c != l && !(o && l > c))
    return !1;
  var u = s.get(e), h = s.get(t);
  if (u && h)
    return u == t && h == e;
  var T = -1, w = !0, C = r & kv ? new Sv() : void 0;
  for (s.set(e, t), s.set(t, e); ++T < c; ) {
    var P = e[T], L = t[T];
    if (n)
      var j = o ? n(L, P, T, t, e, s) : n(P, L, T, e, t, s);
    if (j !== void 0) {
      if (j)
        continue;
      w = !1;
      break;
    }
    if (C) {
      if (!xv(t, function(R, ne) {
        if (!Ev(C, ne) && (P === R || a(P, R, r, n, s)))
          return C.push(ne);
      })) {
        w = !1;
        break;
      }
    } else if (!(P === L || a(P, L, r, n, s))) {
      w = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), w;
}
var _a = jv;
function Cv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
var Iv = Cv;
function Rv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var Nv = Rv, nn = Tt, an = Wn, Pv = tr, Lv = _a, Mv = Iv, Vv = Nv, Zv = 1, Dv = 2, Uv = "[object Boolean]", qv = "[object Date]", Bv = "[object Error]", Hv = "[object Map]", Gv = "[object Number]", zv = "[object RegExp]", Fv = "[object Set]", Kv = "[object String]", Wv = "[object Symbol]", Qv = "[object ArrayBuffer]", Jv = "[object DataView]", sn = nn ? nn.prototype : void 0, Vt = sn ? sn.valueOf : void 0;
function Yv(e, t, r, n, a, s, o) {
  switch (r) {
    case Jv:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case Qv:
      return !(e.byteLength != t.byteLength || !s(new an(e), new an(t)));
    case Uv:
    case qv:
    case Gv:
      return Pv(+e, +t);
    case Bv:
      return e.name == t.name && e.message == t.message;
    case zv:
    case Kv:
      return e == t + "";
    case Hv:
      var c = Mv;
    case Fv:
      var l = n & Zv;
      if (c || (c = Vv), e.size != t.size && !l)
        return !1;
      var u = o.get(e);
      if (u)
        return u == t;
      n |= Dv, o.set(e, t);
      var h = Lv(c(e), c(t), n, a, s, o);
      return o.delete(e), h;
    case Wv:
      if (Vt)
        return Vt.call(e) == Vt.call(t);
  }
  return !1;
}
var Xv = Yv, on = Fn, ey = 1, ty = Object.prototype, ry = ty.hasOwnProperty;
function ny(e, t, r, n, a, s) {
  var o = r & ey, c = on(e), l = c.length, u = on(t), h = u.length;
  if (l != h && !o)
    return !1;
  for (var T = l; T--; ) {
    var w = c[T];
    if (!(o ? w in t : ry.call(t, w)))
      return !1;
  }
  var C = s.get(e), P = s.get(t);
  if (C && P)
    return C == t && P == e;
  var L = !0;
  s.set(e, t), s.set(t, e);
  for (var j = o; ++T < l; ) {
    w = c[T];
    var R = e[w], ne = t[w];
    if (n)
      var Er = o ? n(ne, R, w, t, e, s) : n(R, ne, w, e, t, s);
    if (!(Er === void 0 ? R === ne || a(R, ne, r, n, s) : Er)) {
      L = !1;
      break;
    }
    j || (j = w == "constructor");
  }
  if (L && !j) {
    var Qe = e.constructor, Je = t.constructor;
    Qe != Je && "constructor" in e && "constructor" in t && !(typeof Qe == "function" && Qe instanceof Qe && typeof Je == "function" && Je instanceof Je) && (L = !1);
  }
  return s.delete(e), s.delete(t), L;
}
var ay = ny, Zt = Rn, sy = _a, iy = Xv, oy = ay, cn = Et, un = xt, ln = nr, cy = Vn, uy = 1, dn = "[object Arguments]", fn = "[object Array]", Xe = "[object Object]", ly = Object.prototype, pn = ly.hasOwnProperty;
function dy(e, t, r, n, a, s) {
  var o = un(e), c = un(t), l = o ? fn : cn(e), u = c ? fn : cn(t);
  l = l == dn ? Xe : l, u = u == dn ? Xe : u;
  var h = l == Xe, T = u == Xe, w = l == u;
  if (w && ln(e)) {
    if (!ln(t))
      return !1;
    o = !0, h = !1;
  }
  if (w && !h)
    return s || (s = new Zt()), o || cy(e) ? sy(e, t, r, n, a, s) : iy(e, t, l, r, n, a, s);
  if (!(r & uy)) {
    var C = h && pn.call(e, "__wrapped__"), P = T && pn.call(t, "__wrapped__");
    if (C || P) {
      var L = C ? e.value() : e, j = P ? t.value() : t;
      return s || (s = new Zt()), a(L, j, r, n, s);
    }
  }
  return w ? (s || (s = new Zt()), oy(e, t, r, n, a, s)) : !1;
}
var fy = dy, py = fy, hn = Ae;
function ba(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !hn(e) && !hn(t) ? e !== e && t !== t : py(e, t, r, n, ba, a);
}
var hy = ba, vy = hy;
function yy(e, t) {
  return vy(e, t);
}
var gy = yy;
const my = /* @__PURE__ */ En(gy), _y = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), Dt = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), Ee = {
  equals(e, t) {
    return my(e, t);
  }
}, by = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, im = Object.keys(by), Ty = i.object({
  // v is a stringified number
  v: i.string().regex(/^\d+$/).transform(Number)
}), jt = kt({
  latestVersion: 3,
  versionMap: {
    0: rn,
    1: av,
    2: sv,
    3: dv
  },
  getVersion(e) {
    const t = Ty.safeParse(e);
    return t.success ? t.data.v : rn.schema.safeParse(e).success ? 0 : null;
  }
}), $y = ss({
  id: _y(K),
  v: K,
  auth: Ee,
  body: Ee,
  endpoint: K,
  headers: Dt(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Ee
  ),
  params: Dt(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Ee
  ),
  method: K,
  name: K,
  preRequestScript: K,
  testScript: K,
  requestVariables: Dt(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Ee
  )
}), wy = "3", om = $y.equals;
function cm(e, t) {
  const r = Xp(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = ca.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = fr.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = ha.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = dr.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
    if ("requestVariables" in e) {
      const n = ya.safeParse(e.requestVariables);
      n.success && (r.requestVariables = n.data);
    }
  }
  return r;
}
function um(e) {
  return {
    v: wy,
    ...e
  };
}
function Ay() {
  return {
    v: "3",
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "inherit",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    },
    requestVariables: []
  };
}
function lm(e) {
  return jt.isLatest(e);
}
function Sy(e) {
  const t = jt.safeParse(e);
  return t.type === "ok" ? t.value : Ay();
}
const pr = i.object({
  key: i.string().catch(""),
  value: i.string().catch(""),
  active: i.boolean().catch(!0)
}), xy = i.object({
  v: i.literal(1),
  name: i.string(),
  url: i.string(),
  headers: i.array(pr).catch([]),
  query: i.string(),
  variables: i.string()
}), Ey = U({
  initial: !0,
  schema: xy
}), Ta = i.object({
  authType: i.literal("none")
}), $a = i.object({
  authType: i.literal("basic"),
  username: i.string().catch(""),
  password: i.string().catch("")
}), wa = i.object({
  authType: i.literal("bearer"),
  token: i.string().catch("")
}), Oy = i.object({
  authType: i.literal("oauth-2"),
  token: i.string().catch(""),
  oidcDiscoveryURL: i.string().catch(""),
  authURL: i.string().catch(""),
  accessTokenURL: i.string().catch(""),
  clientID: i.string().catch(""),
  scope: i.string().catch("")
}), Aa = i.object({
  authType: i.literal("api-key"),
  key: i.string().catch(""),
  value: i.string().catch(""),
  addTo: i.string().catch("Headers")
}), Sa = i.object({
  authType: i.literal("inherit")
}), ky = i.discriminatedUnion("authType", [
  Ta,
  $a,
  wa,
  Oy,
  Aa,
  Sa
]).and(
  i.object({
    authActive: i.boolean()
  })
), xa = i.object({
  id: i.optional(i.string()),
  v: i.literal(2),
  name: i.string(),
  url: i.string(),
  headers: i.array(pr).catch([]),
  query: i.string(),
  variables: i.string(),
  auth: ky
}), jy = U({
  initial: !1,
  schema: xa,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), Ea = i.discriminatedUnion("authType", [
  Ta,
  Sa,
  $a,
  wa,
  Aa,
  ma
  // both rest and gql have the same auth type for oauth2
]).and(
  i.object({
    authActive: i.boolean()
  })
), Cy = xa.extend({
  v: i.literal(3),
  auth: Ea
}), Iy = U({
  initial: !1,
  schema: Cy,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: a, authURL: s } = e.auth;
      return {
        ...e,
        v: 3,
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: s,
            tokenEndpoint: r,
            clientID: a,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: 3,
      auth: {
        ...e.auth
      }
    };
  }
}), Oa = 3, Ry = i.object({
  v: i.number()
}), hr = kt({
  latestVersion: 3,
  versionMap: {
    1: Ey,
    2: jy,
    3: Iy
  },
  getVersion(e) {
    const t = Ry.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), Ny = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function Py() {
  return {
    v: Oa,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: Ny,
    auth: {
      authType: "inherit",
      authActive: !0
    }
  };
}
function Ly(e) {
  const t = hr.safeParse(e);
  return t.type === "ok" ? t.value : Py();
}
function dm(e) {
  return {
    v: Oa,
    ...e
  };
}
const My = i.object({
  v: i.literal(1),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        ht(jt),
        ht(hr)
      ])
    )
  )
}), ka = My.extend({
  folders: i.lazy(() => i.array(ka))
}), vn = U({
  initial: !0,
  schema: ka
}), Vy = i.object({
  v: i.literal(2),
  id: i.optional(i.string()),
  // For Firestore ID data
  name: i.string(),
  requests: i.array(
    i.lazy(
      () => i.union([
        ht(jt),
        ht(hr)
      ])
    )
  ),
  auth: i.union([fr, Ea]),
  headers: i.union([dr, i.array(pr)])
}), ja = Vy.extend({
  folders: i.lazy(() => i.array(ja))
}), Zy = U({
  initial: !1,
  schema: ja,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), Dy = i.object({
  v: i.number()
}), fm = kt({
  latestVersion: 2,
  versionMap: {
    1: vn,
    2: Zy
  },
  getVersion(e) {
    const t = Dy.safeParse(e);
    return t.success ? t.data.v : vn.schema.safeParse(e).success ? 1 : null;
  }
}), Uy = 2;
function Ca(e) {
  return {
    v: Uy,
    ...e
  };
}
function qy(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(qy), n = (e.requests ?? []).map(Sy), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = Ca({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
function By(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(By), n = (e.requests ?? []).map(Ly), a = e.auth ?? { authType: "inherit", authActive: !0 }, s = e.headers ?? [], o = Ca({
    name: t,
    folders: r,
    requests: n,
    auth: a,
    headers: s
  });
  return e.id && (o.id = e.id), o;
}
var te = function(e) {
  return function(t) {
    return !e(t);
  };
};
globalThis && globalThis.__spreadArray;
var Hy = $s, Gy = bs;
function Ia(e, t) {
  return t === void 0 ? function(r) {
    return Ia(e, r);
  } : Gy(e, t) ? wn : An(t[e]);
}
var Ra = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, zy = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var a = e(n, t[n]);
      $n(a) && r.push(a.value);
    }
    return r;
  };
}, Fy = function(e) {
  return zy(function(t, r) {
    return e(r);
  });
}, Na = function(e) {
  return e.slice();
}, Ky = _s, yn = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, Wy = function(e) {
  return function(t) {
    return yn(yn([], t, !0), [e], !1);
  };
}, Qy = Wy, Jy = function(e) {
  return [e];
}, Yy = Qy, Xy = Ia, eg = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, tg = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, rg = function() {
  return {
    concat: tg().concat,
    empty: []
  };
}, Ct = wn, Be = An;
function Pa(e) {
  return function(t) {
    return e(t) ? Be(t) : Ct;
  };
}
var Qt = function(e) {
  return function(t) {
    return La(t) ? Ct : Be(e(t.value));
  };
}, ng = $n, La = function(e) {
  return e._tag === "None";
}, ag = function(e, t) {
  return function(r) {
    return La(r) ? e() : t(r.value);
  };
}, sg = ag, vr = sg, ig = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, re = cs, H = us, og = /* @__PURE__ */ ns(2, function(e, t) {
  return ye(e) ? e : t(e.right);
}), He = function(e) {
  return function(t) {
    return ye(t) ? t : H(e(t.right));
  };
}, It = function(e) {
  return function(t) {
    return ye(t) ? re(e(t.left)) : t;
  };
}, ye = is, cg = os, ug = function(e) {
  return function(t) {
    return ye(t) ? e(t.left) : t.right;
  };
}, yr = ug, gn = og, yt = globalThis && globalThis.__assign || function() {
  return yt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, yt.apply(this, arguments);
}, Ge = function(e, t, r) {
  return H({
    value: e,
    next: t,
    start: r
  });
}, ze = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), re({
    input: e,
    expected: t,
    fatal: r
  });
}, lg = function(e, t) {
  return yt(yt({}, e), { expected: t });
}, dg = function(e, t) {
  return fg().concat(e, t);
}, fg = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? ys().concat(e, t) : e.input.cursor > t.input.cursor ? Pt().concat(e, t) : vs({
        input: Pt(),
        fatal: Pt(),
        expected: rg()
      }).concat(e, t);
    }
  };
}, pg = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, hg = function(e) {
  return Xy(e.cursor, e.buffer);
}, vg = function(e) {
  return e.cursor >= e.buffer.length;
}, yg = function(e) {
  return g(hg(e), Qt(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, ee = globalThis && globalThis.__assign || function() {
  return ee = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, ee.apply(this, arguments);
}, gr = function(e) {
  return function(t) {
    return Ge(e, t, t);
  };
}, gg = function() {
  return function(e) {
    return ze(e);
  };
}, mg = function(e) {
  return function() {
    return ze(e);
  };
}, k = function(e) {
  return g(Tg(_g()), le(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? ge(r) : mg(n);
  }));
}, O = function(e, t) {
  return function(r) {
    return g(e(r), It(function(n) {
      return lg(n, [t]);
    }));
  };
}, _g = function() {
  return function(e) {
    return g(yg(e), vr(function() {
      return ze(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return Ge(r, n, e);
    }));
  };
}, bg = function(e, t) {
  return function(r) {
    return g(e(r), gn(function(n) {
      return g(t(n.value)(n.next), gn(function(a) {
        return Ge(a.value, a.next, r);
      }));
    }));
  };
}, Rt = function(e, t) {
  return function(r) {
    var n = e(r);
    return cg(n) || n.left.fatal ? n : g(t()(r), It(function(a) {
      return dg(n.left, a);
    }));
  };
}, Tg = function(e) {
  return function(t) {
    return g(e(t), He(function(r) {
      return ee(ee({}, r), { value: [r.value, t] });
    }));
  };
}, Ma = function(e) {
  return de(function() {
    return ge(e.empty);
  });
}, mr = function() {
  return O(function(e) {
    return vg(e) ? Ge(void 0, e, e) : ze(e);
  }, "end of file");
}, $g = function(e) {
  return g(_r(e), de(function() {
    return ge([]);
  }));
}, _r = function(e) {
  return g(e, le(function(t) {
    return Tr(Jy(t), function(r) {
      return g(e, E(function(n) {
        return re(Yy(n)(r));
      }), de(function() {
        return ge(H(r));
      }));
    });
  }));
}, wg = function(e, t) {
  return function(r) {
    return g(e, le(function() {
      return r;
    }), $r(function() {
      return t;
    }));
  };
}, Va = function(e) {
  return wg(e, e);
}, Ag = function(e) {
  return $g(k(te(e)));
}, br = function(e, t) {
  return g(t, E(function() {
    return Ky;
  }), de(function() {
    return Sg(e, t);
  }));
}, Sg = function(e, t) {
  return g(e, le(function(r) {
    return Tr(ws(r), function(n) {
      return g(t, E(function() {
        return H(n);
      }), de(function() {
        return g(e, E(function(a) {
          return re(Hy(a)(n));
        }));
      }));
    });
  }));
}, Fe = function(e, t) {
  return function(r) {
    return g(e(r), He(function(n) {
      return ee(ee({}, n), { value: t(n.value) });
    }));
  };
}, Za = function(e, t) {
  return Nt(e, function(r) {
    return Fe(t, r);
  });
}, Nt = function(e, t) {
  return bg(e, t);
}, Tr = function(e, t) {
  var r = function(n) {
    return function(a) {
      return ye(a.value) ? re({ value: a.value.left, stream: a.next }) : H(Ge(a.value.right, a.next, n));
    };
  };
  return function(n) {
    return ig({ value: e, stream: n }, function(a) {
      var s = t(a.value)(a.stream);
      return ye(s) ? H(ze(a.stream, s.left.expected, s.left.fatal)) : r(n)(s.right);
    });
  };
}, xg = function(e, t) {
  return Rt(e, t);
}, E = function(e) {
  return function(t) {
    return Fe(t, e);
  };
}, ge = gr, le = function(e) {
  return function(t) {
    return Nt(t, e);
  };
}, $r = function(e) {
  return function(t) {
    return Nt(t, function(r) {
      return Fe(e(r), function() {
        return r;
      });
    });
  };
}, de = function(e) {
  return function(t) {
    return xg(t, e);
  };
}, Eg = "Parser", Og = function(e) {
  return {
    concat: function(t, r) {
      return Za(Fe(t, function(n) {
        return function(a) {
          return e.concat(n, a);
        };
      }), r);
    }
  };
}, kg = function(e) {
  return ee(ee({}, Og(e)), { empty: gr(e.empty) });
}, jg = {
  URI: Eg,
  map: Fe,
  ap: Za,
  chain: Nt,
  chainRec: Tr
}, Da = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, Ua = function(e) {
  return function(t) {
    return g(t, E(function(r) {
      return Da({}, e, r);
    }));
  };
}, Jt = function(e, t) {
  return function(r) {
    return g(r, le(function(n) {
      return g(t(n), E(function(a) {
        return Da(n, e, a);
      }));
    }));
  };
}, Cg = function(e) {
  return gs(e)(e.empty);
};
ms.concat;
var Ig = Ma(er), me = function(e) {
  return O(k(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, Rg = function(e) {
  return O(k(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, wr = function(e) {
  return Ig(Ke(e));
}, Ke = function(e) {
  return g(_r(e), E(function(t) {
    return t.join("");
  }));
}, Ar = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, Yt = O(k(Ar), "a digit"), Ng = /^\s$/, qa = function(e) {
  return Ng.test(e);
}, Ba = O(k(qa), "a whitespace"), Pg = function(e) {
  return e === "_";
}, We = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, Ha = function(e) {
  return We(e) || Ar(e) || Pg(e);
};
O(k(Ha), "a word character");
O(k(We), "a letter");
var Lg = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
O(k(Lg), "an unicode letter");
var Ga = function(e) {
  return We(e) && e === e.toUpperCase();
};
O(k(Ga), "an upper case letter");
var za = function(e) {
  return We(e) && e === e.toLowerCase();
};
O(k(za), "a lower case letter");
O(k(te(Ar)), "a non-digit");
var Fa = O(k(te(qa)), "a non-whitespace character");
O(k(te(Ha)), "a non-word character");
O(k(te(We)), "a non-letter character");
O(k(te(Ga)), "anything but an upper case letter");
O(k(te(za)), "anything but a lower case letter");
var Ka = function(e) {
  return O(jg.chainRec(e, function(t) {
    return g(Zg(0, t), vr(function() {
      return ge(H(e));
    }, function(r) {
      return g(me(r), le(function() {
        return ge(re(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, Xt = Cg(kg(er)), De = Ma(er), Mg = function(e) {
  return De(Vg(e));
}, Vg = function(e) {
  return g(_r(e), E(function(t) {
    return t.join("");
  }));
}, Zg = function(e, t) {
  return e >= 0 && e < t.length ? Be(t.charAt(e)) : Ct;
}, Dg = wr(Ba);
Ke(Ba);
wr(Fa);
Ke(Fa);
var Ug = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? Ct : Be(t);
};
O(g(Xt([De(me("-")), Ke(Yt)]), E(function(e) {
  return +e;
})), "an integer");
O(g(Xt([De(me("-")), wr(Yt), De(Xt([me("."), Ke(Yt)]))]), le(function(e) {
  return g(Ug(e), vr(function() {
    return gg();
  }, gr));
})), "a float");
var qg = Va(me('"'))(Mg(Rt(Ka('\\"'), function() {
  return Rg('"');
})));
function Wa(e) {
  return function(t) {
    return t(pg(e.split("")));
  };
}
const mn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), Bg = ["#", ":"], _e = Va(Dg), Sr = (e) => (t) => t.join(e), xr = (e) => g(
  Ag((t) => e.includes(t)),
  E(Sr(""))
), Hg = Q(
  xr,
  $r(() => k(() => !0))
), Qa = g(
  qg,
  E((e) => JSON.parse(`"${e}"`))
), Gg = g(
  _e(Qa),
  de(
    () => g(
      xr([":", `
`]),
      E(Sn)
    )
  )
), zg = g(
  _e(Qa),
  de(
    () => g(
      xr([`
`]),
      E(Sn)
    )
  )
), Ja = g(
  De(Ka("#")),
  E(te(xn))
), Ya = g(
  _e(Ja),
  Ua("commented"),
  Jt("key", () => _e(Gg)),
  $r(() => me(":")),
  Jt("value", () => zg)
), Fg = g(
  _e(Ja),
  Ua("commented"),
  Jt("key", () => Rt(
    Hg([`
`]),
    () => g(
      br(k((e) => !0), mr()),
      E(Q(
        Na,
        Sr("")
      ))
    )
  )),
  E(Q(
    Pa(({ key: e }) => !xn(e))
  ))
), Kg = g(
  br(_e(Ya), mr())
), Wg = g(
  br(
    Rt(
      g(Ya, E(Be)),
      () => g(
        Fg,
        E(Q(
          Qt((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    mr()
  ),
  E(Q(
    Fy(Q(
      Pa(ng),
      Qt((e) => e.value)
    ))
  ))
), Qg = (e) => {
  if (Bg.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, _n = (e) => Qg(e) ? JSON.stringify(e) : e, pm = (e) => g(
  e,
  eg(
    Q(
      mn("key", _n),
      mn("value", _n),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  Sr(`
`)
), Jg = (e) => g(
  Wg,
  Wa(e),
  It((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  He(
    ({ value: t }) => g(
      t,
      Ra(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), hm = (e) => g(
  Kg,
  Wa(e),
  It((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  He(
    ({ value: t }) => g(
      t,
      Ra(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), vm = Q(
  Jg,
  He(Na),
  yr(() => [])
), Yg = i.object({
  id: i.optional(i.string()),
  name: i.string(),
  variables: i.array(
    i.object({
      key: i.string(),
      value: i.string()
    })
  )
}), bn = U({
  initial: !0,
  schema: Yg
}), Xa = () => Math.random().toString(36).substring(2, 16), Xg = i.object({
  v: i.literal(1),
  id: i.string(),
  name: i.string(),
  variables: i.array(
    i.union([
      i.object({
        key: i.string(),
        secret: i.literal(!0)
      }),
      i.object({
        key: i.string(),
        value: i.string(),
        secret: i.literal(!1).catch(!1)
      })
    ])
  )
}), em = U({
  initial: !1,
  schema: Xg,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id || Xa(),
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), tm = i.object({
  v: i.number()
}), ym = kt({
  latestVersion: 1,
  versionMap: {
    0: bn,
    1: em
  },
  getVersion(e) {
    const t = tm.safeParse(e);
    return t.success ? t.data.v : bn.schema.safeParse(e).success ? 0 : null;
  }
}), gt = /<<([^>]*)>>/g, mt = 10, es = "ENV_EXPAND_LOOP", Tn = 1;
function rm(e, t) {
  let r = e, n = 0;
  for (; r.match(gt) != null && n <= mt; )
    r = r.replace(gt, (a) => {
      const s = t.find(
        (o) => o.key === a.replace(/[<>]/g, "")
      );
      return s && "value" in s ? s.value : a;
    }), n++;
  return n > mt ? re(es) : H(r);
}
const gm = (e, t) => g(
  rm(e, t),
  yr(() => e)
);
function nm(e, t, r = !1) {
  if (!t || !e)
    return H(e);
  let n = e, a = 0;
  for (; n.match(gt) != null && a <= mt; )
    n = decodeURI(encodeURI(n)).replace(gt, (s, o) => {
      const c = t.find((l) => l && l.key === o);
      return c && "value" in c ? c.secret && r ? "*".repeat(
        c.value.length
      ) : c.value : "";
    }), a++;
  return a > mt ? re(es) : H(n);
}
const mm = (e, t, r = !1) => g(
  nm(e, t, r),
  yr(() => e)
), am = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), _m = (e) => {
  if (e.v && e.v === Tn)
    return e;
  const t = e.id || Xa(), r = e.name ?? "Untitled", n = (e.variables ?? []).map(am);
  return {
    v: Tn,
    id: t,
    name: r,
    variables: n
  };
};
export {
  iv as AuthCodeGrantTypeParams,
  ov as ClientCredentialsGrantTypeParams,
  Uy as CollectionSchemaVersion,
  ym as Environment,
  Tn as EnvironmentSchemaVersion,
  oa as FormDataKeyValue,
  pr as GQLHeader,
  Oa as GQL_REQ_SCHEMA_VERSION,
  fm as HoppCollection,
  Ea as HoppGQLAuth,
  Aa as HoppGQLAuthAPIKey,
  $a as HoppGQLAuthBasic,
  wa as HoppGQLAuthBearer,
  Sa as HoppGQLAuthInherit,
  Ta as HoppGQLAuthNone,
  ma as HoppGQLAuthOAuth2,
  hr as HoppGQLRequest,
  fr as HoppRESTAuth,
  fa as HoppRESTAuthAPIKey,
  la as HoppRESTAuthBasic,
  da as HoppRESTAuthBearer,
  pa as HoppRESTAuthInherit,
  ua as HoppRESTAuthNone,
  ma as HoppRESTAuthOAuth2,
  dr as HoppRESTHeaders,
  ca as HoppRESTReqBody,
  sm as HoppRESTReqBodyFormData,
  jt as HoppRESTRequest,
  ya as HoppRESTRequestVariables,
  uv as ImplicitOauthFlowParams,
  cv as PasswordGrantTypeParams,
  wy as RESTReqSchemaVersion,
  im as ValidContentTypesList,
  Py as getDefaultGQLRequest,
  Ay as getDefaultRESTRequest,
  om as isEqualHoppRESTRequest,
  lm as isHoppRESTRequest,
  by as knownContentTypes,
  Ca as makeCollection,
  dm as makeGQLRequest,
  um as makeRESTRequest,
  gm as parseBodyEnvVariables,
  rm as parseBodyEnvVariablesE,
  vm as parseRawKeyValueEntries,
  Jg as parseRawKeyValueEntriesE,
  mm as parseTemplateString,
  nm as parseTemplateStringE,
  pm as rawKeyValueEntriesToString,
  cm as safelyExtractRESTRequest,
  hm as strictParseRawKeyValueEntriesE,
  Ly as translateToGQLRequest,
  _m as translateToNewEnvironment,
  am as translateToNewEnvironmentVariables,
  By as translateToNewGQLCollection,
  qy as translateToNewRESTCollection,
  Sy as translateToNewRequest
};
